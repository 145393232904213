// Async 3:rd part script loading using js/async-load.js
(function (url) {
  setTimeout(function () {
      var iframe = document.createElement('iframe');
      (iframe.frameElement || iframe).style.cssText = 'display:none';
      iframe.src = 'javascript:false';
      var where = document.getElementsByTagName('script')[0];
      where.parentNode.insertBefore(iframe, where);
      var doc = iframe.contentWindow.document;
      doc.open().write('<body onload="' +
          'var js = document.createElement(\'script\');' +
          'js.src = \'' + url + '\';' +
          'document.body.appendChild(js);">');
      doc.close();
  }, 0);
}('//cdn.shopify.com/s/files/1/0613/0169/t/2/assets/async-load.js'));